#floating-icons {
    & {
        position: fixed;
        display: flex;
        flex-direction: column;
        right: 20px;
        bottom: 5%;
    }
    .icon {
        border-radius: 100%;
        padding: 1rem;
        color: #fff;
        font-size: 2.5rem;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 2px rgba(0,0,0,0.5);
    }
}