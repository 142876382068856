.categories-section {

    .flex-container {
        align-items: center;
        justify-content: space-between;
        max-width: $max-width + 500px;
        margin: 2rem auto;
    }
    
}

@media (max-width: 1240px) {
    .categories-section {

        .flex-container {
            margin: 0 auto;
        }
        
    }
}