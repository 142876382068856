.localization {
    
    & {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        background: $dark;
    }

    .info {
        & {
            width: 100%;
            max-width: 580px;
            padding: 1rem 2rem;
        }

        .disk-tag {
            background-color: $red;
            color: $light;
            text-align: center;
            text-transform: uppercase;
            font-family: $secundaryFont;
            font-weight: 900;
            padding: 0 10px;
            width: fit-content;
            font-size: .8em;
            margin-bottom: 1rem;
            line-height: 1.8em;
        }

        .phone {
            & {
                margin: 0;
                display: block;
                font-size: 2em;
                font-weight: bold;
                transition: 0.3s ease;
            }
            &:hover {
                color: $secundary;
            }
            span {
                font-weight: bold;
                font-family: $secundaryFont;
                margin-left: 1rem;
            }
        }

    }

    .info-block {
        & {
            margin: 2rem 0;
            display: flex;
            align-items: center;
        }

        svg {
            font-size: 3em;
            color: $red;
            font-weight: lighter;
            margin-right: 1rem;
            width: 60px;
            transition: 0.3s ease;
        }
        svg:hover {
            color: $light;
        }

        .info-block-text {
            & {
                color: $light;
                opacity: .6;
                font-size: 13pt;
            }
            p {
                margin: 0.3rem 0;
                font-weight: lighter;
                font-family: $secundaryFont;
            }
        }
    }

    .map {
        & {
            width: 50%;
            height: 500px;
        }
        iframe {
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    .social-media {
        & {
            color: $light;
            font-size: 2.5em;
            margin-right: 1rem;
            transition: 0.3s ease;
        }

        &:hover {
            color: $secundary;
        }
    }
}

@media (max-width: 1100px) {
    .localization {
        .info {
            & {
                width: 50%;
            }
        }
    }
}

@media (max-width: 700px) {
    .localization {
        & {
            justify-content: center;
        }
    
        .info {
            & {
                width: 100%;
                max-width: 330px;
            }
            .phone {
                * {
                    font-size: 20pt;
                }
            }
    
        }
    
        .info-block {
            .info-block-text {
                p {
                    font-size: 11pt;
                }
            }
        }
    
        .map {
            & {
                width: 100%;
                height: 400px;
            }
        }
    
        .social {
            text-align: center;
        }
    }
}