.category {
    & {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &:hover,
    &.active {
        & {
            transition: 0.3s ease;
        }
        img {
            border-color: $secundary;
        }
        h3 {
            color: $secundary;
        }
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }

    img {
        width: 280px;
        height: 280px;
        border-radius: 100%;
        border: 8pt solid $light;
        object-fit: cover;
        transition: 0.3s ease;
    }

    h3 {
        margin-top: 20px;
        color: $light;
        font-size: 20pt;
    }
}

@media (max-width: 1240px) {
    .category {
        & {
            margin-bottom: 1rem;
        }
    
        img {
            width: 240px;
            height: 240px;
            border-width: 2pt;
        }
    
        h3 {
            margin-top: 5px;
            font-size: 15pt;
        }
    }
}

@media (max-width: 1000px) {
    .category {
        & {
            margin-bottom: 1rem;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    
        img {
            width: 180px;
            height: 180px;
            border-width: 2pt;
        }
    
        h3 {
            margin-top: 5px;
            font-size: 15pt;
        }
    }
}

@media (max-width: 750px) {
    .category {
        & {
            margin-bottom: 1rem;
            width: 45%;
        }
    
        img {
            width: 100vw * 0.4;
            height: 100vw * 0.4;
            border-width: 2pt;
        }
    
        h3 {
            margin-top: 5px;
            font-size: 15pt;
        }
    }
}