$primary: #ab3636;
$primaryDark: #631F1F;
$secundary: #E8CC0C;
$dark: #232323;
$light: #ffffff;
$red: #FF0000;

$primaryFont: 'Rubik', sans-serif;
$secundaryFont: 'Montserrat', sans-serif;
$darkText: rgb(24, 24, 24);

$max-width: 1180px;

$title-size: 30pt;
$title-size-mobile: 16pt;

* {
    font-family: $primaryFont;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
}

.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: $max-width;
    margin: auto;
}