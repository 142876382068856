.product {
    & {
        min-width: 500px;
        width: 48%;
        border-bottom: 1px solid #ccc;
        cursor: default;
    }

    &:hover {
        .prod-img {
            transform: scale(1.1);
        }
        .ingredients, 
        .prod-prices span,
        .prod-obs { 
            color: $red;
        }
    }

    &:nth-last-child(-n+2) {
        border-color: transparent;
    }

    .container {
        display: flex;
        align-items: center;
        padding: 1rem;
    }

    .prod-img {
        & {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 100%;
            transition: 0.5s ease;
        }
        &:hover {
            cursor: zoom-in;
        }
    }

    .prod-text {
        & {
            width: 70%;
            text-align: left;
            padding: 1rem;
        }
        .title {
            display: flex;
            align-items: center;
            font-weight: bolder;
            font-size: 15pt;
        }
        .ingredients {
            opacity: .6;
            transition: 0.5s ease;
            font-family: $secundary;
            margin-top: .2rem;
        }
        .tag {
            font-size: 10pt;
            color: #fff;
            font-weight: bold;
            font-family: $secundaryFont;
            padding: 2px 5px;
            border-radius: 2px;
            height: fit-content;
            margin-right: 5px;
        }
        .prod-obs {
            font-size: 12pt;
            transition: 0.5s ease;
            font-family: $secundaryFont;
        }
    }

    .prod-prices {
        & {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    .modal-image {
        & {
            position: fixed;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,.6);
            z-index: 3;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .modal-container {
            display: flex;
            position: relative;
            background: #000;
            border-radius: 5px;
        }

        button {
            position: absolute;
            top: -30px;
            right: -30px;
            background: none;
            color: $light;
            border: 0;
            cursor: pointer;
            font-size: 30px;
        }

        img {
            object-fit: contain;
            cursor: auto;
            max-width: 80vw;
            max-height: 600px;
        }
    }
}

@media (max-width: 1000px) {
    .product {
        & {
            min-width: unset;
            width: 100%;
        }
        &:nth-last-child(-n+2) {
            border-bottom: 1px solid #ccc;
        }

        &:nth-last-child(-n+1) {
            border-color: transparent;
        }
    }
}

@media (max-width: 600px) {
    .product {

        .container {
            padding: .2rem .3rem;
        }
    
        .prod-img {
            & {
                width: 70px;
                height: 70px;
            }
        }
    
        .prod-text {
            & {
                padding: .3rem .5rem;
            }
            .title {
                font-size: 10pt;
            }
            .ingredients {
                font-size: .7rem;
            }
            .tag {
                font-size: 6pt;
                padding: 2px 5px;
                margin-right: 3px;
            }
            .prod-obs {
                font-size: .7rem;
                transition: 0.5s ease;
                font-family: $secundaryFont;
            }
        }
    }
}