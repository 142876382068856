.menu {
    & {
        background-color: $primaryDark;
        text-align: center; 
    }
    
    .menu-container {
        height: auto;
        min-height: calc(100vh - 288px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 4rem 3rem;
    }

    h1 {
        color: $light;
        font-size: $title-size;
        text-transform: uppercase;
        margin-bottom: 4rem;
    }

    .product-list {
        & {
            background-color: $light;
        }

        .flex-container {
            padding: 4rem 0;
            justify-content: space-between;
        }
        
        .list-title {
            & {
                width: 100%;
                text-align: center;
                color: $dark;
                margin-bottom: 3rem;
            }
            h2 {
                font-size: 45pt;
                text-transform: capitalize;
                font-family: $secundaryFont;
            }
            p {
                font-family: $secundaryFont;
                font-weight: 100;
                font-size: 18pt;
                opacity: .6;
            }
        }

        .options-title {
            & {
                width: 100%;
                text-align: center;
                color: $dark;
                margin-bottom: 3rem;
            }
            h3 {
                font-size: 25pt;
                text-transform: capitalize;
                font-family: $secundaryFont;
            }
            p {
                font-family: $secundaryFont;
                font-weight: 100;
                font-size: 15pt;
                opacity: .6;
            }
        }
    }

    .download {
        & {
            background-color: $primary;
            padding: 4rem 3rem;
        }
        * {
            color: $light;
        }
        .flex-container {
            justify-content: space-between;
            padding: 0 2rem;
        }

        h3 {
            font-size: 30pt;
            text-transform: uppercase;
        }

        a {
            & {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 3px solid $light;
                font-weight: bold;
                text-transform: uppercase;
                padding: 0 4rem;
                transition: .3s ease;
            }
            &:hover {
                background-color: $secundary;
                color: $primaryDark;
                border-color: $primaryDark;
            }
        }
    }
}

@media (max-width: 1240px) {
    .menu {
        .menu-container {
            padding: 1rem .5rem;
        }
    }
}

@media (max-width: 1000px) {
    .menu {
        .product-list {

            .flex-container {
                padding: 1rem 0;
            }
            
            .list-title {
                & {
                    margin-bottom: 1rem;
                }
                h2 {
                    font-size: 25pt;
                }
                p {
                    font-size: 10pt;
                    opacity: .6;
                }
            }

            .options-title {
                & {
                    margin: 1rem 0;
                }
                h3 {
                    font-size: 16pt;
                }
                p {
                    font-size: 10pt;
                }
            }
        }
    }

    .download {
        & {
            padding: 2rem 1rem;
        }
        .flex-container {
            flex-direction: column;
            justify-content: space-between;
        }

        h3 {
            font-size: 20pt;
        }

        a {
            & {
                margin: 1rem auto 0;
                height: 40px;
            }
        }
    }
}

@media (max-width: 600px) {
    .menu {
        .menu-container {
            min-height: unset;
        }
    
        h1 {
            font-size: $title-size-mobile;
            margin-bottom: 1rem;
        }
    
    }
}