.price {
    & {
        width: 80px;
        margin: 0.3rem 0
    }
    p {
        font-family: $secundaryFont;
        font-weight: 600;
        text-transform: capitalize;
    }
    span {
        font-family: $secundaryFont;
        font-weight: 100;
        transition: 0.5s ease;
    }
}

@media (max-width: 600px) {
    .price {
        & {
            margin: 2px 0;
        }
        * {
            font-size: 9pt;
        }
    }
}