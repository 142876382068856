header {
    & {
        background-color: $primary;
        padding: 2rem;
        box-shadow: 10px;
        position: relative;
    }
    &::before{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background-repeat: repeat;
        height: 10px;
        background-size: 20px 20px;
        background-image:
          radial-gradient(circle at 10px -5px, transparent 12px, $primaryDark 13px);
    }
    &::after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background-repeat: repeat;
        height: 15px;
        background-size: 40px 20px;
        background-image:
          radial-gradient(circle at 10px 15px, $primaryDark 12px, transparent 13px);
    }

    .flex-container {
        align-items: center;
        justify-content: space-between;
    }

    img {
        width: 220px;
    }

    h1 {
        padding: 1rem 10px;
        color: $light;
        font-size: 60pt;
        font-weight: 600;
        text-shadow: 1px 1px 1px rgb(17, 17, 17);
        letter-spacing: 5pt;
    }

    .phones-container {
        & {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .phone {
            & {
                padding: .7rem 2rem;
                background-color: $secundary;
                color: $primaryDark;
                border-radius: 7px;
                margin: 0 .5rem;
                transition: .3s ease;
            }
            &:hover {
                background-color: $primaryDark;
                color: $light;
            }
            span {
                font-family: $secundaryFont;
                font-weight: 900;
            }
        }
    }
}

@media (max-width: 1035px) {
    header {
        & {
            padding: 1rem;
        }

        .flex-container {
            justify-content: center;
        }
    
        img {
            width: 100px;
        }
    
        h1 {
            text-align: center;
            padding: 0 10px 10px;
            font-size: 21pt;
            letter-spacing: 2pt;
        }
    
        .phones-container {
            & {
                margin-bottom: 1rem;
            }
            .phone {
                & {
                    padding: .3rem .8rem;
                    border-radius: 3px;
                    margin: 0 .5rem;
                }
                span {
                    font-family: $secundaryFont;
                    font-weight: 900;
                }
                svg, span {
                    font-size: .7rem;
                }
            }
        }
    }
}