.phone {
    & {
        color: $light;
        margin: 0 1rem;
        font-size: 1rem;
    }

    span {
        font-weight: normal;
        margin-left: 5px;
    }
}